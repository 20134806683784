import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
// import p1 from 'assests/Photos/Clubs/Humanity/CRISIS/IMG_5075.jpg';
// import p2 from 'assests/Photos/Clubs/Humanity/CRISIS/IMG_5077.jpg';
// import p3 from 'assests/Photos/Clubs/Humanity/CRISIS/IMG_5096.jpg';
// import p4 from 'assests/Photos/Clubs/Humanity/CRISIS/IMG_5106.jpg';



import { Typography } from '@mui/material';
import Container from 'components/Container';
import SidebarArticles from 'views/ClassAssembly3E/components/SidebarArticles/SidebarArticles';
import HumanityClub from 'views/HumanityClub';

const CrisisManagement = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [config, setConfig] = useState({ base_image_archive_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/Humanity/CRISIS/1.jpg`;
  const p2 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/Humanity/CRISIS/2.jpg`;
  const p3 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/Humanity/CRISIS/3.jpg`;
  const p4 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/Humanity/CRISIS/4.jpg`;

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },


    // {
    //   src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   source: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   rows: 1,
    //   cols: 1,
    // },
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      ‘CRISIS MANAGEMENT’
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
       Class: 9 to 12  Date: 14 October 2022
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        Commerce Association organised an activity on “Crisis Management” for classes 9 to 12 on
14 October 2022. It was an event centred around the conception of solving modern day business
crisis. The first round was titled ‘Guesstimate’ wherein the estimation and reasoning skills of
students were put to test. The final round comprised of proposing solutions to various case
studies of crisis in major business organisations. The event was aimed at inculcating the skill of
handling the unexpected events as it arise.<br/><br/>
It gave the students the necessary exposure to identify potential threats that may bring down a
company’s reputation in real life situations and give the best possible solution to tackle the same.
Each and every team put their best foot forward and made the event a memorable one!
All the students participated enthusiastically and confidently.
        </Typography>
      </Box>
      <Typography
          variant={'h5'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
        “Crisis does not create character but reveals it”
<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={3}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <HumanityClub />
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default CrisisManagement;